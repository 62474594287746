import React from 'react';

import {
	SPACE_SETTINGS_DETAILS_ANALYTICS_SOURCE,
	LazySpaceDetailsPage,
} from '@confluence/space-settings';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { namedRoutes } from '@confluence/named-routes';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';

import SpaceBase from '../../SpaceBase';
import { fg } from '@confluence/feature-gating';

export const SpaceSettingsDetailsRoute = ({ params: { spaceKey } }) => {
	if (fg('confluence-fe-space-settings-space-details')) {
		return (
			<SpaceBase>
				<LazySpaceDetailsPage spaceKey={spaceKey} />
			</SpaceBase>
		);
	} else {
		return <Redirection name={namedRoutes.SPACE_SETTINGS.name} params={{ spaceKey }} />;
	}
};

SpaceSettingsDetailsRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }) => ({
	Screen: {
		screenEvent: { name: SPACE_SETTINGS_DETAILS_ANALYTICS_SOURCE },
		pageState: { spaceKey },
	},
	MainLayout: {
		navView: getIsNav4Enabled() ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
	},
});
